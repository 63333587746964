import React from "react";
import Post from "./Post";

function HomeFeed() {
  const posts = [
    { id: 1, user: "User1", content: "Day 10 sober!" },
    { id: 2, user: "User2", content: "Grateful for today!" },
  ];

  return (
    <section className="p-4">
      <h2 className="text-xl font-bold">Community Feed</h2>
      {posts.map((post) => (
        <Post key={post.id} user={post.user} content={post.content} />
      ))}
    </section>
  );
}

export default HomeFeed;

