import React from "react";

function EmergencyButton() {
  const handleClick = () => {
    alert("Alerting your sponsor!");
  };

  return (
    <button
      className="fixed bottom-5 right-5 bg-red-600 text-white px-4 py-2 rounded-full shadow-lg"
      onClick={handleClick}
    >
      Emergency
    </button>
  );
}

export default EmergencyButton;

