import React from "react";

function Post({ user, content }) {
  return (
    <div className="border p-3 mb-3 rounded shadow-sm">
      <p><strong>{user}</strong></p>
      <p>{content}</p>
      <button className="text-blue-500">Like</button>
      <button className="text-blue-500 ml-4">Comment</button>
    </div>
  );
}

export default Post;

