import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeFeed from "./components/HomeFeed";
import EmergencyButton from "./components/EmergencyButton";

function App() {
  return (
    <div>
      <Header />
      <main>
        <HomeFeed />
        <EmergencyButton />
      </main>
      <Footer />
    </div>
  );
}

export default App;

